.spinner {
	animation: spinner 1.5s ease-in-out infinite;
}

@keyframes spinner {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}
