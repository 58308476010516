@import '../../common/colors';

.root {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    z-index: 10;
}

.logoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    &>img {
        width: 100px;
        height: 27px;
    }
}

.btnsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    &>a {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #222F4C;
        margin-left: 20px;
    }
}

.signinbtn {
    margin-right: 10px !important;
}