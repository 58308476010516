@import '../../common/colors';

.content {
    width: 250px;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 15px;

    &>p {
        margin: 0px !important;
        margin-bottom: 15px !important;

        &>a {
            margin-left: 10px;
        }
    }
}

.btn {
    text-transform: none !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
}

@media (max-width:600px) {

    .btn {
        font-size: 13px !important;
    }
}