@import '../../common/colors';

.content {
	width: 255px;
	padding: 20px;
	padding-top: 15px;
	padding-bottom: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: #1a3f5d;

	& > a {
		width: 100%;
		margin-bottom: 10px;
		display: flex;
		// justify-content: center;
		align-items: center;
		flex-direction: row;
		text-decoration: none;
		cursor: pointer;

		& > img {
			width: 15px;
			height: 15px;
			margin-right: 10px;
		}

		& > span {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			letter-spacing: 0.02em;

			color: $white;
		}
	}
}

.menuBtn {
	width: 30px;
	min-width: 30px !important;
}

.avatar {
	width: 30px;
	height: 30px;
	border-radius: 40px;
	margin-right: 10px;
	cursor: pointer;
}

.profileBtn {
	min-width: 30px !important;
}
