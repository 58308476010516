@import '../../common/colors';
@import '../../common/mixins';

.root {
	width: 100%;
	padding-left: 40px;
	padding-right: 50px;
	height: 60px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.logoContainer {
	display: flex;
	flex-direction: row;
	align-items: center;

	& > img {
		width: 80px;
		height: 21px;
	}

	& > span {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: $gray-10;
	}
}

.btnsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.btn {
    text-transform: none !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
}

@media (max-width:600px) {
    .root {
        flex-direction: column-reverse;
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        align-items: flex-start;
    }

    .btnsContainer {
        // flex-direction: column;
        // align-items: flex-start;
        margin-bottom: 10px;
    }
    .btn{
        font-size: 13px !important;
    }
}